import info from './info'
import menu from './menu'
import table from './table'

export default {
    userId: 'Nutzer {id}',
    newUser: 'Neuer Nutzer',
    createUser: 'Neuen Nutzer anlegen',

    deleteUser: 'Account löschen',
    deleteUserText:
        'Möchtest du den Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOtherUserText:
        'Möchtest du den Account von {name} wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOwnUserText:
        'Möchtest du deinen Account wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteUserSuccess: 'Nutzer wurde erfolgreich gelöscht.',
    deleteUserError: 'Der Nutzer konnte nicht gelöscht werden.',

    impersonateSuccess: 'Erfolgreich als gewählter Nutzer unterwegs.',
    impersonateError: 'Identität konnte nicht übernommen werden.',
    impersonateBanner: 'Als {fullname} {organisation_name} unterwegs.',
    leaveImpersonationSuccess: 'Identität wurde erfolgreich zurückgesetzt.',
    leaveImpersonationError: 'Identität konnte nicht zurückgesetzt werden.',
    leaveImpersonationLink: 'Zurück',
    updateSuccess: 'Nutzer wurde erfolgreich gespeichert',
    welcome: 'Willkommen {name}!',

    firstname: 'Vorname',
    lastname: 'Nachname',
    email: 'E-Mail',
    profileImage: 'Profilbild',
    profile_image_id: 'Profilbild ID',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    roles: {
        user: 'Mitarbeiter',
        manager: 'Vertriebsleiter',
        admin: 'Administrator'
    },

    info,
    menu,
    table
}