import { default as indexVmCRHzFqHRMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/auth/index.vue?macro=true";
import { default as indexnmS7BfCktZMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as index48IDHriy3WMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/index.vue?macro=true";
import { default as organisationBaseDatao8KqcxgxxJMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93V3rlCUsb6EMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations/[id].vue?macro=true";
import { default as indexK8HqJxlobZMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations/index.vue?macro=true";
import { default as organisationsAvxj2hZvPqMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations.vue?macro=true";
import { default as dungeon97HwBwuRGUMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsWkkblsuyElMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/secret/index/inputs.vue?macro=true";
import { default as index3ObQsTr6aiMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/secret/index.vue?macro=true";
import { default as languagetG5kA6wpIIMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/language.vue?macro=true";
import { default as _91id_93p4fSoVc9APMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexnAPDvowmdiMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93cRpReLUeoqMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexP9QZ7XI5RfMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsrfWmG1aud1Meta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings.vue?macro=true";
import { default as userActivitiesJUOxTL5293Meta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDatapcpFfsQSnkMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunications2qSvTiaisPMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93d72BM7BzQsMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id].vue?macro=true";
import { default as indexdTzmk0HX5WMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/index.vue?macro=true";
import { default as usersJkCCfUeGdiMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93cQe8ULtIbLMeta } from "/home/forge/base.farbcode.net/releases/20241014142344/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexVmCRHzFqHRMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexnmS7BfCktZMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisationsAvxj2hZvPqMeta?.name,
    path: "/organisations",
    meta: organisationsAvxj2hZvPqMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93V3rlCUsb6EMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexK8HqJxlobZMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsrfWmG1aud1Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93p4fSoVc9APMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexnAPDvowmdiMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93cRpReLUeoqMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexP9QZ7XI5RfMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersJkCCfUeGdiMeta?.name,
    path: "/users",
    meta: usersJkCCfUeGdiMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93d72BM7BzQsMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDatapcpFfsQSnkMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexdTzmk0HX5WMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93cQe8ULtIbLMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20241014142344/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]